@font-face {
  font-family: "Earth Orbiter";
  src: url("../fonts/earthorbiterbold.ttf") format("truetype")
}
@font-face {
  font-family: "Nasalization";
  src: url("../fonts/nasalization-rg.otf") format("opentype")
}


body {
  min-height: 100vh;
  background: linear-gradient(#4D26DB 0%, #27124A 100%);
  color: white;
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  font-family: Nasalization;
}
.radial-gradient {
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0;
  min-width: 60%;
  min-height: 60%;
  background: radial-gradient(circle at 100% 30%, #81EDFF 0.5%, #50C6FE 3%, #3F74F4 8%, transparent 20%);
}
.gradient-button {
  padding: 15px 30px;
  border: none;
  border-radius: 31px;
  background: linear-gradient(103deg, #7332F4, #AB34F2);
  box-shadow: 0px 3px 6px #00000029;
  color: white;
  font-size: 16px;
  font-family: Earth Orbiter;
  text-transform: uppercase;
  text-decoration: none;
}
.gradient-button:hover {
  cursor: pointer;
}
.button-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}
.form-input {
  text-indent: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 20px;
  border: none;
  background-color: #00000029;
  font-family: Nasalization;
}
.user-info-input {
  color: white;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.username-input {
  border: 1px solid #fff;
  background: transparent;
}
.cursor-pointer:hover {
  cursor: pointer;
}
.collar-number {
  background: rgba(255,255,255,0.17);
}
.big-spacer {
  margin-top: 20vh;
  margin-bottom: 20vh;
}
.pagination-item p {
  border-bottom: 1px solid #6D36EB;
  color: #6D36EB
}
.active-page p {
  color: white;
  border-bottom: none;
}
